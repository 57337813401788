import React, { forwardRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper'
import { useResolution } from '@ecommerce/shared'
import { SliderWrapper, ThumbnailSwiper } from '../index.styled'
import { useProduct } from '../../context/product.context'

SwiperCore.use([Thumbs, Pagination, Navigation])

const ImageSlider = forwardRef<HTMLDivElement>((_, ref) => {
  const { isDesktop } = useResolution()
  const { product, setSelectedImage } = useProduct()

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null)

  const images = product?.sliderImages ? product?.images?.concat(product?.sliderImages) : product?.images

  const isActiveThumbnails = images && images.length > 1

  return (
    <SliderWrapper ref={ref} isActiveThumbnails={isActiveThumbnails}>
      <Swiper freeMode thumbs={{ swiper: thumbsSwiper }} className="product-swiper">
        {images?.map((image) => (
          <SwiperSlide>
            <button type="button" onClick={() => setSelectedImage(image)}>
              <img src={`${image}`} alt={product?.title} />
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
      {images && isActiveThumbnails && (
        <ThumbnailSwiper
          onSwiper={setThumbsSwiper}
          slidesPerView={isDesktop ? 4 : 3}
          pagination={images?.length > 3}
          watchSlidesProgress
          direction={isDesktop ? 'vertical' : 'horizontal'}
          spaceBetween={isDesktop ? 16 : 24}
          className="thumbnail-swiper"
        >
          {images?.map((image) => (
            <SwiperSlide>
              <img src={`${image}`} alt={product?.title} />
            </SwiperSlide>
          ))}
        </ThumbnailSwiper>
      )}
    </SliderWrapper>
  )
})

export default ImageSlider
