import React from 'react'
import { useResolution, useShoppingCart } from '@ecommerce/shared'
import { AddProductButtonWrapper, DiscountRawWrapper, PricesButtonWrapper } from '../../index.styled'
import { useProduct } from '../../context/product.context'
import AddFavoriteButton from './AddFavoriteButton'
import Discount from './Discount'
import RawPrice from './RawPrice'
import Price from './Price'

const AddProductButtonMobile: React.FC = () => {
  const { product } = useProduct()

  const { isMobile } = useResolution()

  const {
    state: { byHash },
  } = useShoppingCart()

  const productAdded = byHash[product?.skuCode ?? '']
  const quantity = productAdded ? productAdded.quantity : 0

  const activeQuantityPromotion = Boolean(
    product?.promotion?.activationQuantity && quantity > 0 && quantity <= product?.promotion?.activationQuantity - 1,
  )

  return (
    <AddProductButtonWrapper
      initial={activeQuantityPromotion}
      animate={activeQuantityPromotion ? 'active' : 'inactive'}
      variants={{
        active: {
          height: isMobile ? '89px' : '105px',
        },
        inactive: {
          height: isMobile && activeQuantityPromotion ? '88px' : '72px',
        },
      }}
      transition={{
        duration: 0.3,
      }}
      style={{
        overflow: 'hidden',
      }}
      isActivePromotion={activeQuantityPromotion}
    >
      <PricesButtonWrapper isActivePromotion={activeQuantityPromotion}>
        <DiscountRawWrapper>
          <Discount discount={product?.discount} />
          <RawPrice rawPrice={product?.rawPrice} />
        </DiscountRawWrapper>
        <Price price={product?.price} />
      </PricesButtonWrapper>
      <AddFavoriteButton />
    </AddProductButtonWrapper>
  )
}

export default AddProductButtonMobile
