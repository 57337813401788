import styled from 'styled-components'
import { motion } from 'framer-motion'

export const TabWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 16px;
  gap: 64px;
`

export const TabButton = styled(motion.button)<{ active: boolean }>`
  border: none;
  border-block-end: ${({ active, theme }) => (active ? `4px solid ${theme.colors.red}` : 'none')};
  padding-block: 16px;
  padding-inline: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ active, theme }) => (active ? theme.colors.red : theme.colors.black80)};
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.24px;
`
