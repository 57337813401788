import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const RetornabilityTitle = styled.span`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.platinum};

  @media (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
  }
`

export const RetornabilityInfo = styled.p`
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  color: ${({ theme }) => theme.colors.platinum};

  @media (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
  }
`
