import React, { ReactNode } from 'react'
import {
  Image,
  FormattedProduct,
  toCurrency,
  AddProductButton,
  useShoppingCart,
  PromotionType,
} from '@ecommerce/shared'
import {
  Wrapper,
  IconContent,
  ImageContent,
  Content,
  Title,
  Description,
  Info,
  ActionButton,
  DiscountedProduct,
  ProductCardContent,
  ProductCardImage,
  ProductCardMain,
  ProductCardTitle,
  ProductCardPrice,
  DiscountedPrice,
  RawPrice,
} from './InfoWithIcon.styled'
import { IconId, Icon } from '../../../Icon/Icon'

export type contentDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse'

type Props = {
  icon?: IconId
  title?: string
  description?: string | ReactNode
  info?: string
  actionButton?: React.ButtonHTMLAttributes<HTMLButtonElement>
  returnability?: boolean
  discountedProduct?: FormattedProduct
  contentDirection?: contentDirection
  returnabilityType?: string
}
const InfoWithIcon = ({
  icon,
  title,
  discountedProduct,
  description,
  info,
  actionButton,
  returnability,
  contentDirection = 'column',
  returnabilityType,
}: Props) => {
  const isColumnDirection = contentDirection.includes('column')
  const {
    state: { byHash },
  } = useShoppingCart()
  const productAdded = discountedProduct && byHash[discountedProduct.skuCode || '']
  const quantity = productAdded ? productAdded.quantity : 0
  const showAddProductButton = discountedProduct && discountedProduct.promotion?.type !== PromotionType.A_DISCOUNTS_B

  return (
    <Wrapper>
      {icon && (
        <IconContent>
          <Icon iconId={icon} size="24" />
        </IconContent>
      )}
      {returnability && (
        <ImageContent>
          <Image
            src="https://images.ctfassets.net/16npdkkoi5mj/6M8QNmddUrGSuBf9YdZnJW/e76b1ce6d88550326483d1b77312b377/botella.png"
            alt="returnability"
            className="card-image"
          />
        </ImageContent>
      )}
      <Content direction={contentDirection}>
        {title && (
          <Title className={returnability ? 'title-returnability' : ''} returnabilityType={returnabilityType}>
            {title}
          </Title>
        )}
        {description && <Description>{description}</Description>}
        {discountedProduct && (
          <DiscountedProduct>
            <ProductCardContent>
              <ProductCardImage
                alt={discountedProduct.title}
                src={discountedProduct.thumbnail ?? discountedProduct.image}
              />
              <ProductCardMain>
                <ProductCardTitle>{discountedProduct.title}</ProductCardTitle>
                <ProductCardPrice>
                  <DiscountedPrice>
                    {toCurrency(discountedProduct.price > 1 ? discountedProduct.price : 0)}
                  </DiscountedPrice>
                  <RawPrice>{toCurrency(discountedProduct.rawPrice > 1 ? discountedProduct.rawPrice : 0)}</RawPrice>
                </ProductCardPrice>
                {showAddProductButton && (
                  <AddProductButton typeButton="large" product={discountedProduct} quantity={quantity} />
                )}
              </ProductCardMain>
            </ProductCardContent>
          </DiscountedProduct>
        )}
        {info && (
          <Info>
            <Icon iconId="info_outline" size="20" /> {info}
          </Info>
        )}
        {actionButton && (
          <ActionButton
            addMargin={isColumnDirection}
            {...actionButton}
            returnabilityType={returnabilityType}
            className={returnability ? 'action-returnability' : ''}
          >
            {actionButton.children}
          </ActionButton>
        )}
      </Content>
    </Wrapper>
  )
}

export default InfoWithIcon
