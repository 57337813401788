import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Text } from './DescriptionBody.styled'
import { RichTextJson } from '../../../../graphql/contentfulTypes'

interface Props {
  description: RichTextJson
}

const DescriptionBody = ({ description }: Props) => {
  return <Text>{description?.json && documentToReactComponents(description.json)}</Text>
}

export default DescriptionBody
