import styled from 'styled-components'
import { breakpoints, ZIndex } from '@ecommerce/shared'
import { motion } from 'framer-motion'

export const WrapperDetail = styled.div`
  height: auto;
  @media screen and (${breakpoints.desktop.min}) {
    margin-block-end: 50px;
  }
`

export const BreadcrumbsWrapper = styled.div`
  display: none;

  @media screen and (${breakpoints.desktop.min}) {
    display: block;
    width: 864px;
    margin: 0 auto;

    .breadcrumbs {
      background: transparent;
      padding: 0;
      height: auto;
      padding-block-start: 33px;
      padding-block-end: 24px;
    }
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    width: 1280px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  min-height: calc(100vh - 231px);
  padding-inline: 16px;
  padding-block-start: 16px;
  padding-block-end: 56px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
    padding-inline: 40px;
    padding-block: 24px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
    padding-block-end: 56px;
    width: 864px;
    margin: 0 auto;
  }

  @media (${breakpoints.desktopWide.min}) {
    width: 1280px;
  }
`

export const ProductInformationWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (${breakpoints.desktop.min}) {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    width: 536px;
    padding: 24px;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    width: 952px;
  }
`

export const ProductDetailWrapper = styled.section`
  width: 100%;
  @media screen and (${breakpoints.desktop.min}) {
    width: fit-content;
    margin-inline: 0;
  }
`

export const AddProductButtonWrapper = styled(motion.div)<{ isActivePromotion: boolean | undefined }>`
  display: grid;
  grid-template-areas: 'price-detail add-favorite';
  grid-template-columns: 90px 1fr;
  grid-template-rows: 1fr 1fr;
  bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  padding-block: 16px;
  padding-inline: 16px;
  background: ${({ theme }) => theme.colors.background.body};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlFour};
  gap: 0 24px;
  z-index: ${ZIndex.medium};

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding-inline: 72px;
    padding-block: 24px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    display: none;
  }
`

export const PricesButtonWrapper = styled.div<{ isActivePromotion: boolean | undefined }>`
  grid-area: price-detail;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 90px;
`

export const DiscountRawWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

export const DiscountContainer = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.06px;
`

export const RawPriceContainer = styled.span`
  color: ${({ theme }) => theme.colors.platinum80};
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.06px;
  text-decoration: line-through;
`

export const PriceContainer = styled.span`
  color: ${({ theme }) => theme.colors.black80};
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.254px;
`

export const AddFavoriteWrapper = styled.div<{ isVisible: boolean; showHelpText: boolean | undefined }>`
  display: grid;
  grid-template-areas:
    'add favorite'
    'help-text .';
  grid-template-columns: 1fr 40px;
  column-gap: 8px;
  grid-area: add-favorite;
  ${({ showHelpText }) => showHelpText && 'row-gap: 4px;'}

  .add-button {
    grid-area: add;
    ${({ isVisible }) => !isVisible && 'grid-column: span 2;'}
    width: 100%;
    height: 40px;
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.24px;

    &-product-add {
      ${({ isVisible }) => !isVisible && 'grid-column: span 2;'}
    }
  }

  .help-text {
    grid-area: help-text;
    margin: 0 auto;
    ${({ isVisible }) => !isVisible && 'grid-column: span 2;'}
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 366px;
    column-gap: 24px;
  }
`

export const FavoriteButtonWrapper = styled.div<{ isVisible: boolean }>`
  grid-area: favorite;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};

  .add-favorite {
    background: ${({ theme }) => theme.colors.grey20};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    cursor: pointer;
    padding-block-start: 9px;
    padding-block-end: 7px;
    padding-inline: 8px;
    height: 40px;
    width: 40px;

    &.is-favorite {
      svg {
        fill: ${({ theme }) => theme.colors.red80};
        width: 24px;
        height: 24px;
      }
    }
  }
`
