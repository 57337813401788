import React from 'react'
import { useResolution } from '@ecommerce/shared'
import Collapse from '../../components/Collapse/Collapse'
import SpecificationsBody from './SpecificationsBody'
import { Wrapper } from './index.styled'

const Specifications = () => {
  const { isDesktop } = useResolution()

  return (
    <Wrapper>
      {!isDesktop && (
        <Collapse className="collapse">
          <Collapse.Header>Especificaciones</Collapse.Header>
          <Collapse.Body className="collapse-body">
            <SpecificationsBody />
          </Collapse.Body>
        </Collapse>
      )}
      {isDesktop && <SpecificationsBody />}
    </Wrapper>
  )
}

export default Specifications
