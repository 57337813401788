import React from 'react'
import { useLocation } from '@ecommerce/shared'
import { DiscountPrice } from './index.styled'

interface DiscountedPriceProps {
  hasDiscount?: boolean
  rawPrice?: number
}

const DiscountedPrice: React.FC<DiscountedPriceProps> = ({ hasDiscount, rawPrice = 0 }) => {
  const { toCurrency } = useLocation()

  if (!hasDiscount || rawPrice === 0) return null

  return <DiscountPrice>{toCurrency(rawPrice)}</DiscountPrice>
}

export default DiscountedPrice
