import React from 'react'
import { SellUnitsChip } from './index.styled'

interface SellUnitsProps {
  sellUnits?: string
}

const SellUnits: React.FC<SellUnitsProps> = ({ sellUnits }) =>
  sellUnits ? <SellUnitsChip>{sellUnits}</SellUnitsChip> : null

export default SellUnits
