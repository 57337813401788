import React from 'react'
import { Header, Text, Wrapper } from './RowDetail.styled'

interface RowDetailProps {
  children: React.ReactNode
  className?: string
}

const RowDetailHeader: React.FC<RowDetailProps> = ({ children, className }) => {
  return <Header className={className}>{children}</Header>
}

const RowDetailText: React.FC<RowDetailProps> = ({ children }) => {
  return <Text>{children}</Text>
}

interface CompoundedComponent extends React.FC<RowDetailProps> {
  Header: typeof RowDetailHeader
  Text: typeof RowDetailText
}

const RowDetail: CompoundedComponent = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

RowDetail.Header = RowDetailHeader
RowDetail.Text = RowDetailText

export default RowDetail
