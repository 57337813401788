import React, { useEffect } from 'react'
import { PageProps, navigate } from 'gatsby'
import {
  Market,
  Product,
  useAuth,
  useLocation,
  getStoredDistributionCenter,
  DistributionCenter,
  useShoppingCart,
  breakpoints,
  SeoTag,
} from '@ecommerce/shared'
import styled, { ThemeProvider, DefaultTheme } from 'styled-components'
import ProductDetail from '../components/ProductDetail'
import Layout from '../components/Layout'
import withPageTransition from '../components/withPageTransition'
import { ProductViewNode } from '../graphql/productView'
import useTheme from '../hooks/useTheme'
import { useFetchProductDetail } from './utils'
import { FlatLocationProductCategory } from '../types/PgPages'
import { sendProductVisitEvent } from '../utils/events'
import { ProductProvider } from '../components/ProductDetail/context/product.context'
import { ScrollProvider } from '../components/ProductDetail/context/scroll.context'

type Props = PageProps<
  {},
  {
    customProduct: Product & {
      slugLocation: string
      city: string
      currentCity: Market
      seo?: SeoTag
    }
    productViewData: ProductViewNode['template']
    productCategories: FlatLocationProductCategory[]
    currentDistributionCenter: DistributionCenter
  },
  {
    themeId?: string
    originListName?: string
  }
>

const Wrapper = styled.div`
  .navbar-menu-spacer {
    margin-top: 123px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    background: ${({ theme }) => theme.colors.grey20};
  }
`

const ProductView = ({ pageContext, location }: Props) => {
  const locationState = location?.state
  const { customProduct: buildTimeProduct, productViewData, productCategories, currentDistributionCenter } = pageContext
  const { getEntityInfo } = useAuth()

  const { isBolivia } = useLocation()

  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter

  const { product, isError, isFetching } = useFetchProductDetail({
    buildTimeProduct,
    distributionCenter: storedDistributionCenter,
  })
  const { getConnectifCart } = useShoppingCart()

  useEffect(() => {
    if (product) {
      const entityInfo = getEntityInfo()
      const cart = getConnectifCart()
      sendProductVisitEvent(product, isBolivia(), cart, entityInfo)
    }
  }, [])

  useEffect(() => {
    if (isBolivia() && !isFetching && !product.price) navigate('/404')
  }, [isFetching])

  const themeId = locationState?.themeId || product.theme

  const { currentTheme } = useTheme(themeId)

  const mergeThemes = (current: DefaultTheme, extension = currentTheme) =>
    ({
      ...current,
      colors: {
        ...current.colors,
        ...(extension
          ? {
              background: extension.background,
              brandText: extension.brandText,
              brand: extension.brand,
              bodyText: extension.bodyText,
              iconsColor: extension.iconsColor,
              iconsHoverColor: extension.iconsHoverColor,
              buttonPrimaryColor: extension.buttonPrimaryColor,
              buttonBackgroundPrimaryColor: extension.buttonBackgroundPrimaryColor,
              alternativeBodyTextColor: extension.alternativeBodyTextColor,
            }
          : {}),
      },
    } as DefaultTheme)

  return (
    <ProductProvider>
      <ScrollProvider>
        <ThemeProvider theme={mergeThemes}>
          <Wrapper>
            <Layout
              id="layout"
              title={product.seo?.title ?? product.title}
              description={product.seo?.description}
              pathName="productView"
              categories={productCategories}
            >
              <ProductDetail
                product={product}
                isLoading={isFetching}
                isError={isError}
                templateData={productViewData}
                categories={productCategories}
              />
            </Layout>
          </Wrapper>
        </ThemeProvider>
      </ScrollProvider>
    </ProductProvider>
  )
}

export default withPageTransition(ProductView)
