import styled, { css } from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

const commonStyles = css`
  width: 50%;
  padding-block: 4px;
  padding-inline-start: 8px;
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.black80};

  @media screen and (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-inline: 0;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-block-end: 0.5px solid ${({ theme }) => theme.colors.grey};

  @media screen and (${breakpoints.desktop.min}) {
    gap: 48px;
    border: none;
  }
`

export const Header = styled.p`
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 8px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    width: 20%;
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 115px;
  }

  ${commonStyles}
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  ${commonStyles}
`
