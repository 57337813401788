import React, { useMemo } from 'react'
import { useResolution } from '@ecommerce/shared'
import Description from './Description'
import Specifications from './Specifications'
import Features from './Features'
import FeaturesBody from './Features/FeaturesBody'
import { useProduct } from '../context/product.context'
import Tabs from '../components/Tabs/Tabs'

type ContentProps = {
  label: string
  children: React.ReactNode
}

type FeatureComponent = {
  [key: string]: React.FunctionComponent
}

const components: FeatureComponent = {
  Description,
  Specifications,
  Features,
  FeaturesBody,
}

const generateContent = (
  showFeatures: boolean | undefined,
  showDescription: boolean | undefined,
  featuresComponent: keyof typeof components,
): Array<ContentProps> => {
  let content: Array<ContentProps> = []

  content = [
    ...(showDescription ? [{ label: 'Descripción', children: <Description /> }] : []),
    ...(showFeatures
      ? [{ label: 'Características', children: React.createElement(components[featuresComponent]) }]
      : []),
    {
      label: 'Especificaciones',
      children: <Specifications />,
    },
  ]

  return content
}

const ProductInformation = () => {
  const { isDesktop } = useResolution()
  const { product } = useProduct()

  const showFeatures = useMemo(
    () => product?.wineDetail && !Object.values(product.wineDetail).every((detail) => detail === undefined),
    [product],
  )

  const showDescription = useMemo(() => !!product?.collapseItemsCollection?.items[0]?.content, [product])

  const featuresComponent = isDesktop ? 'FeaturesBody' : 'Features'

  const content = generateContent(showFeatures, showDescription, featuresComponent)

  return (
    <>
      {!isDesktop && content.map((collapse) => collapse.children)}
      {isDesktop && (
        <Tabs>
          {content.map((tab) => (
            <Tabs.Tab label={tab.label}>{tab.children}</Tabs.Tab>
          ))}
        </Tabs>
      )}
    </>
  )
}

export default ProductInformation
