import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled.div`
  width: 100%;
  padding-inline: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.borderRadius};
`

export const Header = styled(motion.button)`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 12px;
  padding-inline: 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Title = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.black80};
`

export const Body = styled(motion.section)`
  padding-block: 16px;
`
