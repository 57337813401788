import React from 'react'
import { DiscountContainer } from '../../index.styled'

interface DiscountProps {
  discount?: number
}
const Discount = ({ discount }: DiscountProps) => {
  if (!discount) return null
  return <DiscountContainer>{discount}%</DiscountContainer>
}

export default Discount
