import React from 'react'
import { AddProductButton, useAuth, useFavoriteProducts, useLocation, useShoppingCart } from '@ecommerce/shared'
import PromotionHelpText from '@ecommerce/shared/src/components/molecules/AddProductButton/PromotionHelpText'
import LimitedHelpText from '@ecommerce/shared/src/components/molecules/ProductCard/LimitedHelpText'
import FavoriteButton from './FavoriteButton'
import { useProduct } from '../../context/product.context'
import { useCartStockErrorHandler } from '../../../../utils/errors'
import { AddFavoriteWrapper } from '../../index.styled'
import secrets from '../../../../config/secrets'

const AddFavoriteButton = () => {
  const {
    state: { isAuth },
  } = useAuth()

  const { isError, isLoading, product } = useProduct()

  const stockErrorHandler = useCartStockErrorHandler()

  const {
    state: { byHash },
  } = useShoppingCart()

  const { isBolivia } = useLocation()

  const {
    shouldRenderProductCardButton: shouldRenderAddFavoriteButton,
    isFavorite,
    onButtonClick: onAddFavoriteClick,
  } = useFavoriteProducts({ sku: Number(product?.skuCode) })

  const skusLimited = secrets.SKU_LIST_LIMITED_SALE.split(',').map((sku) => Number(sku))

  const productAdded = byHash[product?.skuCode ?? '']
  const quantity = productAdded ? productAdded.quantity : 0

  const activeQuantityPromotion = Boolean(
    product?.promotion?.activationQuantity && quantity > 0 && quantity <= product?.promotion?.activationQuantity - 1,
  )

  const showHelpText = product?.promotion
    ? activeQuantityPromotion && product?.promotion && Boolean(product?.promotion?.activationQuantity)
    : false

  const showErrorSkusLimited = () => {
    return skusLimited.includes(Number(product?.skuCode)) && quantity >= 3
  }

  return (
    <AddFavoriteWrapper isVisible={isAuth} showHelpText={showHelpText}>
      {product && (
        <AddProductButton
          isLoading={isLoading}
          stockErrorHandler={stockErrorHandler}
          typeButton="large"
          product={{ ...product, unavailable: isError || product.unavailable }}
          className="add-button"
          quantity={quantity}
          disabled={product?.isDummy || (!!productAdded?.labelUrl && quantity === 1)}
          promotionActivationQuantity={product?.promotion?.activationQuantity}
          showHelpText={false}
        />
      )}
      {shouldRenderAddFavoriteButton && !product?.isDummy && product?.hasStock ? (
        <FavoriteButton onClick={onAddFavoriteClick} isFavorite={isFavorite} />
      ) : null}

      {showHelpText && product?.promotion?.activationQuantity && (
        <PromotionHelpText
          className="help-text"
          text={`Faltan ${product?.promotion?.activationQuantity - quantity} para la ${
            isBolivia() ? 'oferta' : 'promoción'
          }`}
        />
      )}
      {showErrorSkusLimited() && <LimitedHelpText className="help-text" text="Máximo 3 unidades por compra." />}
    </AddFavoriteWrapper>
  )
}

export default AddFavoriteButton
