import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { ModalBackground } from '@ecommerce/shared'
import { useProduct } from '../context/product.context'
import { Wrapper, DetailWrapper, AddFavoriteWrapperDesktop } from './index.styled'
import ReturnabilityLabels from './ReturnabilityLabels'
import BrandHeader from './BrandHeader'
import PriceDetailsAndQuantity from './PriceDetailsAndQuantity'
import ImageSlider from './ImageSlider'
import AddFavoriteButton from './AddProductButtonMobile/AddFavoriteButton'
import NotifyModal from './NotifyModal'
import AddFavoriteModal from './AddFavoriteModal'
import InfoList from './InfoList'
import { useScroll } from '../context/scroll.context'

interface ProductDetailProps {
  categorySlug: string
}

const ProductDetail = ({ categorySlug }: ProductDetailProps) => {
  const { product } = useProduct()

  const { detailRef, sliderRef } = useScroll()

  const [showAddNotifyModal, setShowAddNotifyModal] = useState(false)
  const [showAddFavoriteModal, setShowAddFavoriteModal] = useState(false)

  const onNavigateToFavorites = () => navigate(`/mis-favoritos`)

  return (
    <Wrapper>
      {showAddNotifyModal && product && (
        <ModalBackground zIndex="10">
          <NotifyModal product={product} onCancel={() => setShowAddNotifyModal(false)} />
        </ModalBackground>
      )}
      {showAddFavoriteModal && product && (
        <AddFavoriteModal
          onNavigateToFavorites={onNavigateToFavorites}
          onCancel={() => setShowAddFavoriteModal(false)}
          product={product}
        />
      )}
      <ImageSlider ref={sliderRef} />
      <DetailWrapper ref={detailRef}>
        <ReturnabilityLabels />
        <BrandHeader categorySlug={categorySlug} />
        <PriceDetailsAndQuantity />
        <AddFavoriteWrapperDesktop>
          <AddFavoriteButton />
        </AddFavoriteWrapperDesktop>
        {product && (
          <div>
            <InfoList
              product={product}
              setShowAddNotifyModal={setShowAddNotifyModal}
              setShowAddFavoriteModal={setShowAddFavoriteModal}
            />
          </div>
        )}
      </DetailWrapper>
    </Wrapper>
  )
}

export default ProductDetail
