import React from 'react'
import { useLocation } from '@ecommerce/shared'
import { Price } from './index.styled'

interface NormalPriceProps {
  price?: number
}

const NormalPrice: React.FC<NormalPriceProps> = ({ price }) => {
  const { toCurrency } = useLocation()

  if (!price) return null

  return <Price>{toCurrency(price)}</Price>
}

export default NormalPrice
