import React, { useState } from 'react'
import * as yup from 'yup'
import {
  toCssPrefix,
  Button,
  ProductCard,
  Product,
  getAuth,
  TextField,
  StoredCustomer,
  saveUserFavoriteSkus,
  useAuth,
  useLocation,
  getStoredDistributionCenter,
  useFavoriteProducts,
} from '@ecommerce/shared'
import { Icon } from '../../../Icon/Icon'
import {
  Wrapper,
  Inner,
  CloseContainer,
  CloseButton,
  CloseResultButton,
  TitleContainer,
  Title,
  ResultIndicator,
  GuestForm,
  ButtonContainer,
} from './AddFavoriteModal.styled'

type Props = { product?: Product; onCancel?: () => void; onNavigateToFavorites: () => void }

const { cssPrefix, toPrefix } = toCssPrefix('AddFavoriteModal__')

const emailSchema = yup.string().email('Debes ingresar un email valido')

type FormValue = { value?: string; errorMessage?: string }

const AddFavoriteModal = ({ product, onCancel, onNavigateToFavorites }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const { isFavorite } = useFavoriteProducts({ sku: Number(product?.skuCode) })

  const [formValues, setFormValues] = useState<{ email?: FormValue; name?: FormValue }>()

  const isAuth = getAuth()
  const { textByCountry } = useLocation()
  const { state: authState, setFavorites } = useAuth()
  const currentDistributionCenter = getStoredDistributionCenter()

  const canSubmit = !isAuth
    ? !!formValues?.email?.value && !formValues?.email?.errorMessage && !!formValues?.name?.value
    : true

  const validateEmail = async () => {
    try {
      await emailSchema.validate(formValues?.email?.value)

      return true
    } catch (error) {
      setFormValues((prev) => ({ ...prev, email: { ...(prev?.email ?? {}), errorMessage: (error as Error).message } }))

      return false
    }
  }

  const getPayload = (): StoredCustomer => {
    const customerName = isAuth ? `${authState.firstName} ${authState.lastName}` : formValues?.name?.value
    const customerEmail = isAuth ? authState.email : formValues?.email?.value
    const clayerId = authState.ownerId.length > 0 ? authState.ownerId : undefined

    if (!currentDistributionCenter?.slug || !customerName || !customerEmail) {
      throw new Error('city, customerName and customerEmail are required')
    }

    return {
      clayerId,
      slugLocation: currentDistributionCenter.slug,
      name: customerName,
      email: customerEmail,
      favoriteSkus: [Number(product?.skuCode)],
    }
  }

  const onAdd = async () => {
    try {
      if (!canSubmit) return
      if (isSuccess) {
        if (!isAuth) return
        return onNavigateToFavorites()
      }

      if (!isAuth) {
        const emailIsValid = await validateEmail()

        if (!emailIsValid) return
      }

      setIsLoading(true)
      setIsSuccess(false)
      setIsError(false)

      await saveUserFavoriteSkus(getPayload())

      if (isAuth) {
        setFavorites([...new Set([...(authState?.favoriteSkus ?? []), Number(product?.skuCode)])])
      }
      setShowMessage(true)
      setIsSuccess(true)
    } catch (error) {
      setIsSuccess(false)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const [resultHeading, resultText] = React.useMemo(() => {
    if (isSuccess) {
      if (!isAuth)
        return ['', `Te enviaremos un correo cuando este producto esté en ${textByCountry('promoción', 'oferta')}`]

      return [
        '¡Agregado a Mis Favoritos!',
        `Te enviaremos un correo cuando esté en ${textByCountry('promoción', 'oferta')}`,
      ]
    }

    if (isError) {
      return ['Ha habido un error agregando el producto', 'Por favor intenta de nuevo']
    }

    return [null, null]
  }, [isSuccess, isAuth, isError])

  const renderSubmitButton = () => {
    let text = 'Guardar'
    let action: () => Promise<void> = onAdd
    if (isSuccess && !isAuth) {
      text = 'Cerrar'
      if (onCancel) {
        action = async () => {
          await onCancel()
        }
      }
    }

    if (isAuth) {
      if (isSuccess || isFavorite) text = 'Ir a Mis Favoritos'
      else text = 'Agregar'
    }

    return (
      <Button onClick={action} isLoading={isLoading} isDisabled={isLoading || !product || !canSubmit}>
        {text}
      </Button>
    )
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = e.currentTarget

    setFormValues((prev) => ({ ...(prev ?? {}), [inputName]: { value } }))
  }

  return (
    <Wrapper zIndex="10" className={cssPrefix}>
      <Inner>
        <CloseContainer>
          <CloseButton onClick={() => (onCancel ? onCancel() : null)} disabled={isLoading} type="button" title="Cerrar">
            <Icon iconId="close" />
          </CloseButton>
        </CloseContainer>
        <TitleContainer>
          {!isAuth && <Icon iconId="mail_subject" />}
          <Title>{isAuth ? `Agregar a Mis Favoritos` : 'Avísame cuando esté en promoción'}</Title>
        </TitleContainer>
        {(isSuccess || isError) && showMessage ? (
          <ResultIndicator>
            <div>
              <Icon
                className={`is-${isSuccess ? 'success' : 'error'}`}
                iconId={isSuccess ? 'success' : 'error_outline'}
              />
            </div>
            <div>
              {resultHeading && resultHeading?.length > 0 && <p>{resultHeading}</p>}
              <span>{resultText}</span>
            </div>

            <CloseResultButton onClick={() => setShowMessage(false)} disabled={isLoading} type="button" title="Cerrar">
              <Icon iconId="close" />
            </CloseResultButton>
          </ResultIndicator>
        ) : null}
        {isAuth ? (
          <>
            {product && (
              <ProductCard
                showAddButton={false}
                className={toPrefix('card')}
                product={{ ...(product ?? {}), unavailable: isLoading }}
              />
            )}
          </>
        ) : (
          <GuestForm>
            <TextField
              onChange={onInputChange}
              className={toPrefix('guest-form-input')}
              placeholder="Ingresa tu nombre"
              label="Nombre"
              name="name"
              disabled={isSuccess}
            />
            <TextField
              onChange={onInputChange}
              className={toPrefix('guest-form-input')}
              placeholder="Ingresa tu correo electrónico"
              label="Correo electrónico"
              name="email"
              errorMessage={formValues?.email?.errorMessage}
              status={formValues?.email?.errorMessage ? 'error' : undefined}
              disabled={isSuccess}
              autoComplete="nope"
              autoCapitalize="nope"
            />
          </GuestForm>
        )}
        <ButtonContainer>{renderSubmitButton()}</ButtonContainer>
      </Inner>
    </Wrapper>
  )
}

export default AddFavoriteModal
