import React from 'react'
import { useProduct } from '../../context/product.context'
import { formatText } from '../../utils'
import { BrandName, ProductName, Wrapper, BrandNameAnchor } from './index.styled'

interface BrandHeaderProps {
  categorySlug: string
}

const BrandHeader = ({ categorySlug }: BrandHeaderProps) => {
  const { product } = useProduct()

  const brandName = product?.brandName || ''
  const categoryUrl = `/${categorySlug}?brandName=${encodeURIComponent(brandName)}`

  return (
    <Wrapper>
      <BrandNameAnchor href={categoryUrl}>
        <BrandName>{formatText(brandName)}</BrandName>
      </BrandNameAnchor>
      <ProductName>{formatText(product?.title || '')}</ProductName>
    </Wrapper>
  )
}

export default BrandHeader
