import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react'
import { percentageToAmount, toCurrency } from '@ecommerce/shared'
import { ProductViewNode } from '../../../graphql/productView'
import { generateSpecifications, generateWineFeatures, Specification, WineFeature } from '../utils'
import { DetailViewProduct } from '../types'

interface ProductContextProps {
  product: DetailViewProduct | undefined
  setProduct: Dispatch<SetStateAction<DetailViewProduct | undefined>>
  setTemplateData: Dispatch<SetStateAction<ProductViewNode['template'] | undefined>>
  getSpecifications: () => Specification[]
  getWineFeatures: () => WineFeature[]
  generatePromotionLabel: () => string | null
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isError: boolean
  setIsError: Dispatch<SetStateAction<boolean>>
  selectedImage?: string
  setSelectedImage: Dispatch<SetStateAction<string | undefined>>
}

const ProductContext = React.createContext<ProductContextProps>({} as ProductContextProps)

ProductContext.displayName = 'ProductContext'

export const ProductProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [product, setProduct] = useState<DetailViewProduct>()
  const [templateData, setTemplateData] = useState<ProductViewNode['template']>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined)

  const getSpecificationsWrapper = useCallback((): Specification[] => {
    return generateSpecifications({
      categoryLabel: templateData?.categoryLabel,
      category: product?.categoryName,
      brandLabel: templateData?.brandLabel,
      brand: product?.brandName,
      flavorLabel: templateData?.flavorLabel,
      flavor: product?.flavorName,
      netContentLabel: templateData?.netContentLabel,
      netContent: `${product?.netContent} ${product?.unitName}`,
      sizeTypeLabel: templateData?.sizeTypeLabel,
      sizeType: product?.sizeType,
      packingLabel: templateData?.packingLabel,
      packing: product?.packing,
      returnabilityLabel: templateData?.returnabilityLabel,
      returnability: product?.returnability,
      sugarLabel: templateData?.sugarLabel,
      sugar: product?.sugar,
      skuLabel: templateData?.skuLabel,
      sku: product?.skuCode,
    })
  }, [product, templateData])

  const getSpecifications = useMemo(() => getSpecificationsWrapper, [getSpecificationsWrapper])

  const getWineFeaturesWrapper = useCallback((): WineFeature[] => {
    return generateWineFeatures({
      grapeLabel: 'Cepa',
      grape: product?.wineDetail?.grapeWine,
      grapeIcon: 'grape',
      locationLabel: 'Valle',
      location: product?.wineDetail?.locationWine,
      locationIcon: 'valley',
      pairingLabel: 'Maridaje',
      pairing: product?.wineDetail?.pairingWine,
      pairingIcon: 'meal',
      phLabel: 'PH',
      ph: product?.wineDetail?.phWine,
      phIcon: 'ph',
      acidityLabel: 'Acidez',
      acidity: product?.wineDetail?.acidityWine,
      acidityIcon: 'acidity',
      alcoholLabel: 'Alcohol',
      alcohol: product?.wineDetail?.alcoholWine,
      alcoholIcon: 'alcohol',
      residualSugarLabel: 'Azúcar residual',
      residualSugar: product?.wineDetail?.residualSugarWine,
      residualSugarIcon: 'residual-sugar',
      agingLabel: 'Envejecimiento',
      aging: product?.wineDetail?.agingWine,
      agingIcon: 'aging',
    })
  }, [product])

  const getWineFeatures = useMemo(() => getWineFeaturesWrapper, [getWineFeaturesWrapper])

  const generatePromotionLabel = (): string | null => {
    if (
      !product?.promotion ||
      !product?.promotion.productCardLabel ||
      typeof product?.promotion.productCardLabel !== `string`
    )
      return null

    const { discountPercentage, isRaw, productCardLabel } = product.promotion
    const useRawPrice = isRaw && product.rawPrice > 0

    let discountText = productCardLabel

    if (discountText === '$calculatedPrice' && discountPercentage) {
      const amount = percentageToAmount(discountPercentage, useRawPrice ? product.rawPrice : product?.price)

      discountText = toCurrency(amount)
    }
    return discountText
  }

  const value = {
    product,
    setProduct,
    setTemplateData,
    getSpecifications,
    getWineFeatures,
    generatePromotionLabel,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    selectedImage,
    setSelectedImage,
  }

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
}

export const useProduct = () => {
  const context = React.useContext(ProductContext)

  if (context === undefined) {
    throw new Error('useProduct must be used within ProductProvider')
  }

  return context
}
