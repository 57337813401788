import React from 'react'
import { useResolution } from '@ecommerce/shared'
import Collapse from '../../components/Collapse/Collapse'
import DescriptionBody from './DescriptionBody'
import { Wrapper } from './index.styled'
import { useProduct } from '../../context/product.context'

const Description = () => {
  const { isDesktop } = useResolution()

  const { product } = useProduct()

  const description = product?.collapseItemsCollection?.items[0]?.content

  if (!description) return null

  return (
    <Wrapper>
      {!isDesktop && (
        <Collapse className="collapse">
          <Collapse.Header>Descripción</Collapse.Header>
          <Collapse.Body>
            <DescriptionBody description={description} />
          </Collapse.Body>
        </Collapse>
      )}
      {isDesktop && <DescriptionBody description={description} />}
    </Wrapper>
  )
}

export default Description
