import styled from 'styled-components'
import { breakpoints, hexToRGBA, Image, ReturnabilityType } from '@ecommerce/shared'
import { contentDirection } from '.'

export const Wrapper = styled.div`
  display: flex;
  padding: 24px 0;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  width: 100%;

  @media (${breakpoints.desktop.min}) {
    padding: 32px 16px;
  }
`
export const IconContent = styled.div`
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: #f44040;
  background-color: #fee5e6;
  margin-right: 16px;
`

export const ImageContent = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  .card-image {
    width: 40px;
    height: 40px;
  }
`

export const Content = styled.div<{ direction?: contentDirection }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ direction }) => direction};
  gap: 8px;
  ${({ direction }) => direction?.includes('row') && `align-items: center; justify-content: flex-end`}
`

export const Title = styled.div<{ returnabilityType?: string }>`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: theme.colors.red80;
  &.title-returnability {
    color: ${({ theme, returnabilityType }) => {
      if (returnabilityType === ReturnabilityType.RETURNABLE) {
        return theme.colors.returnability
      }
      if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
        return theme.colors.skyBlue
      }
      if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
        return theme.colors.blue
      }
    }};
  }
  @media (${breakpoints.desktop.min}) {
    font-size: 17px;
  }
`

export const Description = styled.div`
  font-size: 13px;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: -0.06px;
  display: flex;
  flex-direction: column;

  @media (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
  }
`

export const Info = styled.div`
  font-size: 12px;
  font-weight: 325;
  line-height: 16px;
  padding: 4px;
  color: ${({ theme }) => theme.colors.platinum80};
  background-color: ${({ theme }) => theme.colors.grey40};
  border-radius: 4px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const DiscountedProduct = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.platinum20};
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  .add-button {
    width: 100%;
  }
`

export const ProductCardContent = styled.div`
  display: flex;
  gap: 16px;
`

export const ProductCardImage = styled(Image)`
  width: 64px;
  height: 64px;
`

export const ProductCardMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const ProductCardTitle = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: 325;
  color: ${({ theme }) => theme.colors.black80};
  margin: 0;
`

export const ProductCardPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const DiscountedPrice = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.green};
  margin: 0;
`

export const RawPrice = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 325;
  color: ${({ theme }) => theme.colors.platinum80};
  text-decoration: line-through;
  margin: 0;
`

export const ActionButton = styled.button<{ returnability?: boolean; addMargin?: boolean; returnabilityType?: string }>`
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.red80};
  margin-top: ${({ addMargin }) => (addMargin ? '-4px' : '0')};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: fit-content;

  &.action-returnability {
    color: ${({ theme, returnabilityType }) => {
      if (returnabilityType === ReturnabilityType.RETURNABLE) {
        return theme.colors.returnability
      }
      if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
        return theme.colors.skyBlue
      }
      if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
        return theme.colors.blue
      }
    }};
  }

  @media (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
  }
`
