import React from 'react'
import { Wrapper } from './index.styled'
import Returnability from './Returnability/Returnability'
import Tag from './Tag'
import { useProduct } from '../../context/product.context'

const ReturnabilityLabels = () => {
  const { product } = useProduct()
  return (
    <Wrapper>
      <Tag type="large" tag={product?.tags} />
      <Returnability isRecyclable={product?.recyclable} />
    </Wrapper>
  )
}

export default ReturnabilityLabels
