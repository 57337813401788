import styled from 'styled-components'
import { breakpoints, hexToRGBA } from '@ecommerce/shared'
import Chip from '../../components/Chip/Chip'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Price = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  letter-spacing: 0.111px;
  color: ${({ theme }) => theme.colors.black80};

  @media screen and (${breakpoints.tabletLandscape.min}) {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.11px;
  }
`

export const DiscountPrice = styled.span`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -1.06px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.platinum80};
  @media screen and (${breakpoints.tabletLandscape.min}) {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -1.06px;
  }
`

export const DiscountChip = styled(Chip)``

export const SellUnitsChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.black80};
  background-color: ${({ theme }) => hexToRGBA(theme.colors.grey60, 0.5)};
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: -0.06px;
`

export const PromotionChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
  border-radius: 5px;
  padding-inline: 16px;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    font-size: 14px;
  }
`
