import { HeartIcon } from '@ecommerce/shared/src/components/molecules/ProductCard/styled'
import React from 'react'
import { useAuth } from '@ecommerce/shared'
import { FavoriteButtonWrapper } from '../../index.styled'

interface Props {
  isFavorite: boolean
  onClick: () => void
}

const FavoriteButton = ({ isFavorite, onClick }: Props) => {
  const {
    state: { isAuth },
  } = useAuth()

  return (
    <FavoriteButtonWrapper isVisible={isAuth}>
      <button
        onClick={onClick}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        className={`add-favorite ${isFavorite && 'is-favorite'}`}
        type="button"
        title="Agregar a Mis Favoritos"
      >
        <HeartIcon />
      </button>
    </FavoriteButtonWrapper>
  )
}

export default FavoriteButton
