import React from 'react'
import Collapse from '../../components/Collapse/Collapse'
import FeaturesBody from './FeaturesBody'
import { Wrapper } from './index.styled'

const Features = () => {
  return (
    <Wrapper>
      <Collapse>
        <Collapse.Header>Características</Collapse.Header>
        <Collapse.Body className="collapse-body">
          <FeaturesBody />
        </Collapse.Body>
      </Collapse>
    </Wrapper>
  )
}

export default Features
