import { ReturnabilityType, useLocation } from '@ecommerce/shared'
import React from 'react'
import { RetornabilityInfo, RetornabilityTitle } from './ReturnabilityDescription.styled'

type ReturnabilityDescriptionProps = {
  returnabilityType: ReturnabilityType
}

const ReturnabilityDescription = ({ returnabilityType }: ReturnabilityDescriptionProps) => {
  const { textByCountry } = useLocation()

  if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
    return (
      <>
        <RetornabilityTitle>
          {textByCountry('¡Compra y guarda este envase!', '¡Compra y guarda esta botella!')}
        </RetornabilityTitle>
        <RetornabilityInfo>
          {textByCountry('Producto incluye precio por envase.', 'Producto incluye precio por botella.')}
        </RetornabilityInfo>
        <RetornabilityInfo>
          {textByCountry(
            'Utiliza este envase en tu próxima compra de un producto que no tenga costo por envase.',
            'Utiliza esta botella en tu próxima compra de un producto que no tenga costo por botella.',
          )}
        </RetornabilityInfo>
      </>
    )
  }

  if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
    return (
      <>
        <RetornabilityTitle>
          {textByCountry('¡Compra y guarda este envase y jaba!', '¡Compra y guarda esta botella y caja!')}
        </RetornabilityTitle>
        <RetornabilityInfo>
          {textByCountry('Producto incluye precio por envase y jaba.', 'Producto incluye precio por botella y caja.')}
        </RetornabilityInfo>
        <RetornabilityInfo>
          {textByCountry(
            'Utilízalos en tu próxima compra de un producto que no tenga costo por envase y jaba.',
            'Utilízalos en tu próxima compra de un producto que no tenga costo por botella y caja.',
          )}
        </RetornabilityInfo>
      </>
    )
  }

  return (
    <>
      <RetornabilityTitle>
        {textByCountry('Producto no incluye precio por envase.', 'Producto no incluye precio por botella.')}
      </RetornabilityTitle>
      <RetornabilityInfo>
        {textByCountry(
          'Debes tener un envase retornable vacío para hacer el intercambio con el repartidor.',
          'Debes tener una botella retornable vacía para hacer el intercambio con el repartidor.',
        )}
      </RetornabilityInfo>
    </>
  )
}

export default ReturnabilityDescription
