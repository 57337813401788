import { IconId } from '../../Icon/Icon'

type PropType = Record<string, string | undefined>

interface CommonProps {
  label?: string
  value?: string
}

export type Specification = CommonProps

export interface WineFeature extends CommonProps {
  icon?: IconId
}

export const isValueValid = (value: string | undefined): boolean => !!value

/**
 * Generates an array of transformed props based on the inputProps using the provided transform function.
 *
 * @template T - The type of the transformed props objects.
 * @param {PropType} inputProps - The input props object.
 * @param {number} step - The step size used for grouping the keys.
 * @param {(keys: string[], props: PropType) => T} transform - The function to transform the keys and inputProps into the transformed props object.
 * @returns {T[]} - The array of transformed props objects.
 */
const generateProps = <T extends CommonProps>(
  inputProps: PropType,
  step: number,
  transform: (keys: string[], props: PropType) => T,
): T[] => {
  const keys = Object.keys(inputProps)
  const propObjects: T[] = []

  for (let i = 0; i < keys.length; i += step) {
    const item: T | undefined = isValueValid(inputProps[keys[i + 1]])
      ? transform(keys.slice(i, i + step), inputProps)
      : undefined
    if (item !== undefined) propObjects.push(item)
  }
  return propObjects
}

/**
 * Generates an array of Specifications objects based on the given props.
 *
 * @param {PropType} props - The props to generate specifications from.
 * @return {Specification[]} - The generated specifications.
 */
export const generateSpecifications = (props: PropType): Specification[] =>
  generateProps<Specification>(
    props,
    2,
    (keys, inputProps): Specification => ({
      label: inputProps[keys[0]],
      value: inputProps[keys[1]],
    }),
  )

/**
 * Generates an array of WineFeature objects based on the given props.
 *
 * @param {PropType} props - The input props for generating the WineFeatures.
 * @returns {WineFeature[]} - The array of generated WineFeature objects.
 */
export const generateWineFeatures = (props: PropType): WineFeature[] =>
  generateProps<WineFeature>(
    props,
    3,
    (keys, inputProps): WineFeature => ({
      label: inputProps[keys[0]],
      value: inputProps[keys[1]],
      icon: inputProps[keys[2]] as IconId,
    }),
  )

/**
 * Converts the given text to a specific format.
 *
 * @param {string} text - The text to be formatted.
 * @returns {string} - The formatted text.
 */
export const formatText = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .replace(/(?:^|\s|["'(-])+\S/g, (match) => match.toUpperCase())
}
