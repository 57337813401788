import React, { useMemo } from 'react'
import { getReturnabilityType, ReturnabilityType } from '@ecommerce/shared'
import { Container } from './Returnability.styled'
import { useProduct } from '../../../context/product.context'
import { RetornabilityIcon } from '../../../../Icons'

interface ReturnabilityProps {
  isRecyclable?: boolean
}

const Returnability: React.FC<ReturnabilityProps> = ({ isRecyclable }) => {
  const { product } = useProduct()

  const returnabilityType = useMemo(() => {
    return getReturnabilityType({ returnabilityLabel: product?.returnabilityLabel })
  }, [product?.returnabilityLabel])

  if (!isRecyclable) return null

  return (
    <Container returnabilityType={returnabilityType}>
      {returnabilityType === ReturnabilityType.RETURNABLE && <RetornabilityIcon />}
      {product?.returnabilityLabel}
    </Container>
  )
}

export default Returnability
