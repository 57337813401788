import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Text = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: -0.06px;
  margin: 0;
  color: ${({ theme }) => theme.colors.black80};

  @media screen and (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.15px;
  }
`
