import React from 'react'
import { DiscountChip } from './index.styled'

interface DiscountProps {
  discount?: number
}

const Discount: React.FC<DiscountProps> = ({ discount }) =>
  discount && discount > 0 ? <DiscountChip>{discount}%</DiscountChip> : null

export default Discount
