import React, { useState } from 'react'
import { TabButton, TabWrapper } from './Tabs.styled'

interface TabProps {
  label: string | undefined
}

const Tab: React.FC<TabProps | undefined> = ({ children }) => {
  if (children === undefined) return null
  return <>{children}</>
}

export interface TabsProps {
  children: (React.ReactElement<TabProps | undefined> | undefined)[]
}

interface TabsCompounded extends React.FC<TabsProps> {
  Tab: React.FC<TabProps>
}

const Tabs: TabsCompounded = ({ children }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <TabWrapper>
        {React.Children.map(children, (child, index) => {
          if (child) {
            return (
              <TabButton
                layoutId="buble"
                onClick={() => setActiveTab(index)}
                key={index}
                active={index === activeTab}
                transition={{
                  type: 'spring',
                  bounce: 0.25,
                  duration: 0.6,
                }}
              >
                {child?.props?.label}
              </TabButton>
            )
          }
        })}
      </TabWrapper>
      {React.Children.toArray(children)[activeTab]}
    </>
  )
}

Tabs.Tab = Tab

export default Tabs
