import React from 'react'
import RowDetail from '../../components/RowDetail'
import { Wrapper } from './FeaturesBody.styled'
import { Icon } from '../../../Icon/Icon'
import { useProduct } from '../../context/product.context'
import { WineFeature } from '../../utils'

const FeaturesBody: React.FC = () => {
  const { getWineFeatures } = useProduct()
  return (
    <Wrapper>
      {getWineFeatures().map((wineFeature: WineFeature) => (
        <RowDetail className="row-detail">
          <RowDetail.Header className="row-header">
            {wineFeature.icon && <Icon iconId={wineFeature.icon} size={20} fillColor="platinum60" />}
            <span>{wineFeature.label}</span>
          </RowDetail.Header>
          <RowDetail.Text>{wineFeature.value}</RowDetail.Text>
        </RowDetail>
      ))}
    </Wrapper>
  )
}

export default FeaturesBody
