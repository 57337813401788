import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding-block: 4px;
  padding-inline: 8px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.1px;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.254px;
  }
`
