import React from 'react'
import { useLocation } from '@ecommerce/shared'
import { PriceContainer } from '../../index.styled'

interface PriceProps {
  price?: number
}

const Price = ({ price }: PriceProps) => {
  const { toCurrency } = useLocation()

  if (!price) return null

  return <PriceContainer>{toCurrency(price)}</PriceContainer>
}

export default Price
