import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Divisor = styled.div<{
  hiddenDesktop?: boolean
}>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.platinum20};

  ${({ hiddenDesktop }) => `
    @media (${breakpoints.desktop.min}) {
      ${hiddenDesktop ? 'display:none' : ''}
    }
  `}
`

export const Text = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: -0.06px;
  margin: 24px 0px;
  color: ${({ theme }) => theme.colors.black80};

  @media screen and (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.15px;
    margin: 0px;
  }
`
