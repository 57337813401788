import React, { RefObject, useEffect, createContext, useContext, useRef } from 'react'

interface ScrollContextProps {
  containerRef: RefObject<HTMLDivElement>
  detailRef: RefObject<HTMLDivElement>
  sliderRef: RefObject<HTMLDivElement>
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined)

export const ScrollProvider: React.FC = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const detailRef = useRef<HTMLDivElement>(null)
  const sliderRef = useRef<HTMLDivElement>(null)

  const setOverflowY = (elem: HTMLElement | null, value: string) => {
    if (elem !== null && typeof elem.style !== 'undefined') {
      // eslint-disable-next-line no-param-reassign
      elem.style.overflowY = value
    }
  }

  const adjustOverflowOnScroll = (
    e: WheelEvent,
    scrollableDiv: HTMLElement | null,
    containerDiv: HTMLElement | null,
  ) => {
    if (!scrollableDiv || !containerDiv) return

    const newScrollableDiv = scrollableDiv

    setOverflowY(document.body, 'hidden')
    setOverflowY(containerDiv, 'hidden')

    newScrollableDiv.scrollTop += e.deltaY

    const isTop = newScrollableDiv.scrollTop === 0
    const isBottom = newScrollableDiv.scrollHeight - newScrollableDiv.clientHeight === newScrollableDiv.scrollTop

    if (isTop || isBottom) {
      setOverflowY(document.body, 'auto')
      setOverflowY(containerDiv, 'auto')
    }
  }

  const handleWheel = (e: WheelEvent) => {
    if (sliderRef.current?.contains(e.target as Node)) {
      adjustOverflowOnScroll(e, detailRef.current, containerRef.current)
      e.preventDefault()
    }
  }

  const handleScroll = () => {
    if (!detailRef.current) return
    const scrollableDiv = detailRef.current
    const sliderDiv = sliderRef.current
    const containerDiv = containerRef.current

    if (!containerDiv || !sliderDiv) return

    const isBottom = scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight

    if (isBottom) {
      setOverflowY(document.body, 'auto')
      setOverflowY(sliderDiv, 'auto')
    }
  }

  useEffect(() => {
    if (detailRef.current) {
      detailRef.current.addEventListener('scroll', handleScroll)
    }

    window.addEventListener('wheel', handleWheel)

    return () => {
      if (detailRef.current) {
        detailRef.current.removeEventListener('scroll', handleScroll)
      }
      window.removeEventListener('wheel', handleWheel)
    }
  }, [containerRef, detailRef, sliderRef])

  return <ScrollContext.Provider value={{ containerRef, detailRef, sliderRef }}>{children}</ScrollContext.Provider>
}

export const useScroll = () => {
  const context = useContext(ScrollContext)
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider')
  }
  return context
}
