import React, { useMemo } from 'react'
import {
  Product,
  ReturnabilityType,
  getAuth,
  getReturnabilityType,
  useFavoriteProducts,
  useLocation,
} from '@ecommerce/shared'
import { navigate } from 'gatsby'
import InfoWithIcon from '../InfoWithIcon'
import { sendReturnabilityClickEvent } from '../../../../utils/events'
import { Divisor, Text } from './InfoList.styled'
import ReturnabilityDescription from '../ReturnabilityDescription'

const determineIcon = (isAuth: boolean, isFavorite: boolean) => {
  if (isAuth) {
    return isFavorite ? 'heart' : 'heart_outline'
  }
  return 'mail_subject'
}

const determineButtonLabel = (isAuth: boolean, isFavorite: boolean) => {
  if (isAuth) {
    return isFavorite ? 'Agregado a Mis Favoritos' : 'Agregar a Mis Favoritos'
  }
  return 'Avísame'
}

interface InfoListProps {
  product: Product
  setShowAddNotifyModal: (show: boolean) => void
  setShowAddFavoriteModal: (show: boolean) => void
}

const InfoList = ({ product, setShowAddNotifyModal, setShowAddFavoriteModal }: InfoListProps) => {
  const { textByCountry } = useLocation()

  const { isFavorite } = useFavoriteProducts({ sku: Number(product?.skuCode) })
  const isAuth = getAuth()

  const returnabilityType = useMemo(() => {
    return getReturnabilityType({ returnabilityLabel: product.returnabilityLabel })
  }, [product.returnabilityLabel])

  const returnabilityTitle = useMemo(() => {
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
      return textByCountry('Producto + Envase', 'Producto + Botella')
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
      return textByCountry('Producto + Envase + Jaba', 'Producto + Botella + Caja')
    }
    return textByCountry('Retorna tu envase', 'Retorna tu botella')
  }, [returnabilityType])

  const infoIconType = determineIcon(isAuth, isFavorite)
  const infoButtonLabel = determineButtonLabel(isAuth, isFavorite)

  return (
    <>
      {product && <Divisor hiddenDesktop />}
      {returnabilityType !== ReturnabilityType.NONE && (
        <>
          <InfoWithIcon
            returnability
            returnabilityType={returnabilityType}
            title={returnabilityTitle}
            description={<ReturnabilityDescription returnabilityType={returnabilityType} />}
            actionButton={{
              children: 'Más información',
              onClick: () => {
                sendReturnabilityClickEvent()
                navigate(`/momento/sustentable`)
              },
            }}
          />
          <Divisor />
        </>
      )}
      {product?.promotion && !product.promotion.discountedProduct?.isDummy && (
        <>
          <InfoWithIcon
            title={product.promotion.productDetailTitle}
            icon="Promociones"
            description={product.promotion.productDetailDescription}
            info={product.promotion.productDetailInfoText}
            discountedProduct={product.promotion.discountedProduct}
          />
          <Divisor />
        </>
      )}
      {product?.unavailable ? (
        <InfoWithIcon
          icon="mail_subject"
          description="cuando esté disponible"
          actionButton={{ children: 'Notificarme', onClick: () => setShowAddNotifyModal(true) }}
          contentDirection="row-reverse"
        />
      ) : (
        <InfoWithIcon
          icon={infoIconType}
          description="¿Quiéres saber cuando esté en promoción?"
          actionButton={{
            children: infoButtonLabel,
            onClick: () => setShowAddFavoriteModal(true),
          }}
        />
      )}
      {product.productDescription && (
        <>
          <Divisor hiddenDesktop />
          <Text>{product.productDescription}</Text>
        </>
      )}
      {product && <Divisor hiddenDesktop />}
    </>
  )
}

export default InfoList
