import styled from 'styled-components'
import { Swiper } from 'swiper/react'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (${breakpoints.desktop.min}) {
    flex-direction: row;
    justify-content: center;
    gap: 0;
  }
`

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (${breakpoints.desktop.min}) {
    padding-inline-start: 56px;
    padding-inline-end: 40px;
    padding-block-start: 24px;
    padding-block-end: 40px;
    border-block-end: 1px solid ${({ theme }) => theme.colors.grey};
    border-inline-start: none;
    width: 432px;
    overflow-y: auto;
    max-height: 620px;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    width: 640px;
  }
`

export const SliderWrapper = styled.div<{ isActiveThumbnails?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: ${({ isActiveThumbnails }) => (isActiveThumbnails ? '335px' : '232px')};
  width: 232px;
  margin: 0 auto;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide button {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
  }

  .swiper-slide img {
    display: block;
    width: 232px;
    height: 232px;
    object-fit: cover;
    cursor: pointer;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .product-swiper {
    height: 232px;
    width: 232px;
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    height: ${({ isActiveThumbnails }) => (isActiveThumbnails ? '580px' : '400px')};
    width: 400px;
    gap: 24px;

    .swiper-slide img {
      width: 400px;
      height: 400px;
    }

    .product-swiper {
      height: 400px;
      width: 400px;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 432px;
    height: 620px;
    gap: 24px;
    padding-block: 24px;
    padding-inline: 40px;
    margin-inline: 0;
    border-block-end: 1px solid ${({ theme }) => theme.colors.grey};
    border-inline-end: 1px solid ${({ theme }) => theme.colors.grey};

    .swiper-slide img {
      display: block;
      width: 256px;
      height: 256px;
      object-fit: cover;
    }

    .product-swiper {
      margin-inline: 0;
      height: 256px;
      width: 256px;
    }
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    width: 640px;
    height: 620px;

    .swiper-slide img {
      display: block;
      width: 432px;
      height: 432px;
      object-fit: cover;
    }

    .product-swiper {
      margin-inline: 0;
      height: 432px;
      width: 432px;
    }
  }
`

export const ThumbnailSwiper = styled(Swiper)`
  height: 88px;
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .swiper-pagination {
    height: 8px;
    bottom: 0;
    &-bullets {
      bottom: 8px;
    }

    &-bullet {
      &-active {
        background: ${({ theme }) => theme.colors.red};
        opacity: 0.75;
      }
    }
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 64px;
    opacity: 0.25;
  }

  .swiper-slide img {
    display: block;
    object-fit: cover;
    width: 64px;
    height: 64px;
  }

  .swiper-slide-thumb-active {
    opacity: 1;
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    width: 400px;
    height: 156px;

    .swiper-slide {
      background-size: cover;
      background-position: center;
      max-width: 100%;
      height: 120px !important;
      max-height: 120px !important;
      opacity: 0.25;
    }

    .swiper-slide img {
      display: block;
      object-fit: cover;
      width: 120px;
      height: 120px;
    }

    .swiper-pagination {
      height: 12px;
      bottom: 0;
      &-bullets {
        bottom: 8px;
      }

      &-bullet {
        height: 12px;
        width: 12px;
        &-active {
          background: ${({ theme }) => theme.colors.red};
          opacity: 0.75;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    height: 256px;
    width: 80px;
    margin-inline: 0;

    .swiper-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .swiper-pagination {
      height: 256px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: -8px;
    }

    .swiper-slide {
      background-size: cover;
      background-position: center;
      max-width: 100%;
      height: 56px !important;
      max-height: 56px !important;
      opacity: 0.25;
    }

    .swiper-slide img {
      display: block;
      object-fit: cover;
      width: 56px;
      height: 56px;
    }
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    height: 432px;
    width: 80px;

    .swiper-pagination {
      height: 432px;
    }

    .swiper-slide {
      height: 80px !important;
      max-height: 80px !important;
    }

    .swiper-slide img {
      width: 80px;
      height: 80px;
    }
  }
`

export const AddFavoriteWrapperDesktop = styled.div`
  display: none;

  @media screen and (${breakpoints.desktop.min}) {
    display: flex;
  }
`
