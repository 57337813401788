import styled from 'styled-components'
import { ModalBackground, breakpoints, toCssPrefix } from '@ecommerce/shared'
import { modalMountAnimation } from '../utils'

const { cssPrefix } = toCssPrefix('AddFavoriteModal__')

export const Wrapper = styled(ModalBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${modalMountAnimation} 0.1s linear forwards;

  .${cssPrefix} {
    &card {
      margin: 30px auto;
      cursor: auto;

      .ProductCard__title {
        margin-bottom: 60px;
      }
    }
  }
`

export const Inner = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  max-width: 390px;
  min-height: 330px;
  padding: 30px;
  position: relative;
  width: auto;
  margin: 0 16px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    min-width: 435px;
  }
`

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.colors.black};
    opacity: 0.75;
  }
`

export const CloseResultButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.colors.platinum80};
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  display: flex;
  column-gap: 8px;

  svg {
    color: ${({ theme }) => theme.colors.red};
  }
`

export const Title = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  max-width: 250px;
  @media screen and (${breakpoints.tabletPortrait.min}) {
    max-width: unset;
    font-size: 24px;
  }
`

export const ResultIndicator = styled.div`
  margin: 28px auto;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 15px;
  background: #e7f9f0;
  border-radius: 4px;
  padding: 8px;
  border-left: 4px solid ${({ theme }) => theme.colors.green};

  p {
    font-size: 16px;
    margin: 0;
    width: auto;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.platinum80};
    display: block;
    width: 100%;
  }

  svg {
    width: 24px;
    height: 24px;

    &.is-success {
      fill: ${({ theme }) => theme.colors.green};
    }

    &.is-error {
      fill: ${({ theme }) => theme.colors.error};
    }
  }
`

export const GuestForm = styled.div`
  margin: 30px auto;

  &-input {
    margin-bottom: 16px;
    label {
      font-size: 15px;
      color: #030f1c;
      line-height: 20px;
      letter-spacing: -0.15px;
      font-weight: 325;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    padding: 0 25px;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    button {
      min-width: 140px;
    }
  }
`
