import React from 'react'
import { PriceWrapper, Wrapper } from './index.styled'
import { useProduct } from '../../context/product.context'
import Discount from './DiscountChip'
import NormalPrice from './NormalPrice'
import DiscountedPrice from './DiscountedPrice'
import SellUnits from './SellUnits'
import PromotionDetail from './PromotionDetail'

const PriceDetailsAndQuantity = () => {
  const { product } = useProduct()

  return (
    <Wrapper>
      <Discount discount={product?.discount} />
      <PriceWrapper>
        <NormalPrice price={product?.price} />
        <DiscountedPrice hasDiscount={product?.hasDiscount} rawPrice={product?.rawPrice} />
      </PriceWrapper>
      <PromotionDetail />
      <SellUnits sellUnits={product?.sellUnits} />
    </Wrapper>
  )
}

export default PriceDetailsAndQuantity
