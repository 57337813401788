import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const Wrapper = styled.div`
  .collapse {
    @media screen and (${breakpoints.desktop.min}) {
      display: none;
    }
  }
`
