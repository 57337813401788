import React, { ReactNode, useState } from 'react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { defaultTheme } from '@ecommerce/shared'
import { Icon } from '../../../Icon/Icon'
import { Body, Header, Title, Wrapper } from './Collapse.styled'

interface CollapseContextProps {
  isOpen: boolean
  toggle: () => void
}

interface CollapseProps {
  children: ReactNode
  className?: string
}

const CollapseContext = React.createContext<CollapseContextProps | undefined>(undefined)

const CollapseHeader: React.FC = ({ children }) => {
  const context = React.useContext(CollapseContext) as CollapseContextProps

  if (!context) {
    throw new Error('CollapseHeader must be used within a Collapse')
  }

  const { toggle, isOpen } = context

  const borderVariants: Variants = {
    open: {
      borderBlockEndStyle: 'solid',
      borderBlockEndWidth: '1px',
      borderBlockEndColor: [defaultTheme.colors.white, defaultTheme.colors.red],
      transition: {
        duration: 0.3,
        times: [0, 1],
      },
    },
    closed: {
      borderBlockEndColor: [defaultTheme.colors.red, defaultTheme.colors.white],
      transition: {
        duration: 0.3,
        times: [0, 1],
      },
    },
  }

  return (
    <Header
      animate={isOpen ? 'open' : 'closed'}
      variants={borderVariants}
      initial={false}
      type="button"
      className="title-container"
      onClick={toggle}
    >
      <Title>{children}</Title>
      <motion.div initial={{ rotate: 0 }} animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
        <Icon size={24} iconId="chevron_down" fillColor="platinum60" />
      </motion.div>
    </Header>
  )
}

const CollapseBody: React.FC<CollapseProps> = ({ children, className }) => {
  const context = React.useContext(CollapseContext) as CollapseContextProps

  if (!context) {
    throw new Error('CollapseBody must be used within a Collapse')
  }

  const { isOpen } = context

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <Body
          className={className}
          transition={{ duration: 0.3 }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
        >
          {children}
        </Body>
      )}
    </AnimatePresence>
  )
}

interface CompoundedComponent extends React.FC<CollapseProps> {
  Header: typeof CollapseHeader
  Body: typeof CollapseBody
}

const Collapse: CompoundedComponent = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <CollapseContext.Provider value={{ isOpen, toggle }}>
      <Wrapper className={className} data-testid="collapse">
        {children}
      </Wrapper>
    </CollapseContext.Provider>
  )
}

Collapse.Header = CollapseHeader
Collapse.Body = CollapseBody

export default Collapse
