import React from 'react'
import { useLocation } from '@ecommerce/shared'
import { RawPriceContainer } from '../../index.styled'

interface RawPriceProps {
  rawPrice?: number
}
const RawPrice = ({ rawPrice }: RawPriceProps) => {
  const { toCurrency } = useLocation()

  if (!rawPrice) return null

  return <RawPriceContainer>{toCurrency(rawPrice)}</RawPriceContainer>
}

export default RawPrice
