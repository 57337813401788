import React from 'react'
import styled from 'styled-components'
import { Anchor, breakpoints, hexToRGBA, ModalBackground } from '@ecommerce/shared'
import { Icon } from '../../../Icon/Icon'
import { useProduct } from '../../context/product.context'

const BackgroundModalWrapper = styled(ModalBackground)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  opacity: 0.95;
  width: 100%;
`

const HeaderWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  gap: 24px;

  > span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.15px;
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    top: 40px;
    right: 40px;
    gap: 8px;
    width: 600px;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 600px;
    position: initial;
    margin: 0 auto 24px;
  }
`

const BodyWrapper = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.img`
  width: 375px;
  height: 375px;
  aspect-ratio: 1 / 1;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    width: 600px;
    height: 600px;
  }
`

const ImageModal = () => {
  const { selectedImage, setSelectedImage } = useProduct()

  return (
    <BackgroundModalWrapper className="ModalBackground__RemoveScroll">
      <HeaderWrapper>
        <span>Cerrar</span>
        <Anchor onClick={() => setSelectedImage(undefined)}>
          <Icon fillColor="white" iconId="close" size="25" />
        </Anchor>
      </HeaderWrapper>
      <BodyWrapper>
        <ImageWrapper src={selectedImage} alt="image" />
      </BodyWrapper>
    </BackgroundModalWrapper>
  )
}

export default ImageModal
