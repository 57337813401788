import React from 'react'
import RowDetail from '../../components/RowDetail'
import { formatText, Specification } from '../../utils'
import { Wrapper } from './SpecificationBody.styled'
import { useProduct } from '../../context/product.context'

const SpecificationsBody: React.FC = () => {
  const { getSpecifications } = useProduct()

  return (
    <Wrapper>
      {getSpecifications().map((specification: Specification) => (
        <RowDetail>
          <RowDetail.Header>{specification.label}</RowDetail.Header>
          <RowDetail.Text>{formatText(specification?.value ?? '')}</RowDetail.Text>
        </RowDetail>
      ))}
    </Wrapper>
  )
}

export default SpecificationsBody
