import styled from 'styled-components'
import { breakpoints, ReturnabilityType } from '@ecommerce/shared'

export const Container = styled.span<{ returnabilityType?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  place-content: center;
  width: fit-content;
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-wrap: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, returnabilityType }) => {
    if (returnabilityType === ReturnabilityType.NONE) {
      return 'transparent'
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE) {
      return theme.colors.returnability
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
      return theme.colors.skyBlue
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
      return theme.colors.blue
    }
  }};

  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.06px;
  }
`
