import React from 'react'
import { StyledTag } from './index.styled'

interface TagProps {
  tag?: string
  type: string
}
const Tag: React.FC<TagProps> = ({ tag, type }) => {
  if (tag === undefined || tag === null) return null
  return <StyledTag tag={tag} type={type} />
}

export default Tag
