import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.colors.black80};
  div:last-child {
    border: none;
  }

  .row-header {
    padding-block: 20px;
    height: 52px;
    width: 150px;
  }
`
