import React from 'react'
import { useProduct } from '../../context/product.context'
import { PromotionChip } from './index.styled'

const PromotionDetail: React.FC = () => {
  const { generatePromotionLabel } = useProduct()
  const promotionLabel = generatePromotionLabel()

  if (!promotionLabel) return null

  return <PromotionChip>{promotionLabel}</PromotionChip>
}
export default PromotionDetail
